// https://github.com/fkhadra/react-toastify/issues/963
'use client';

import { useTheme } from 'next-themes';
import { PropsWithChildren } from 'react';
import { toast, ToastContainer } from 'react-toastify';

export default function ToastProvider({ children }: PropsWithChildren) {
  const { theme } = useTheme();
  return (
    <>
      {children}
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        theme={theme === 'night' ? 'dark' : 'light'}
      />
    </>
  );
}
