'use client';

import { ToastProvider } from '@/components/Providers';
import { ThemeProvider } from 'next-themes';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider defaultTheme="night">
      <ToastProvider>
        <DndProvider backend={HTML5Backend}>{children}</DndProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}
